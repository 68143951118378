import { Error404 } from 'modules/Error404';
import { Page } from 'packages/layout';
import { Authorize } from 'packages/utilities/withAgentAuth/Authorize';

const Error404Page = () => (
	<Authorize>
		<Page>
			<Error404 />
		</Page>
	</Authorize>
);

export default Error404Page;
