import { Component, PropsWithChildren } from 'react';

import { Code, ConnectError } from '@connectrpc/connect';
import { setCookie } from 'nookies';

type AuthorizeErrorBoundaryState = {
	statusError?: number;
};

enum HttpStatus {
	Unauthorized = 401,
	Forbidden = 403
}

export class AuthorizeErrorBoundary extends Component<
	PropsWithChildren,
	AuthorizeErrorBoundaryState
> {
	state = {
		statusError: undefined
	};

	componentDidCatch = (err: ConnectError) => {
		switch (err.code) {
			case Code.Unauthenticated:
			case Code.PermissionDenied:
				setCookie(null, 'redirectUrl', window.location.pathname, {
					path: '/',
					httpOnly: false
				});
				window.location.href = '/experts/sign_in';
			default:
				this.setState({ statusError: err.code || HttpStatus.Unauthorized });
		}
	};

	render = () => (this.state.statusError !== undefined ? <AuthorizeError /> : this.props.children);
}

const AuthorizeError = () => {
	return <div>Error</div>;
};
